<template>
    <div class="d-flex flex-no-wrap justify-center">
      <div class="text-center font-weight-bold clock-time">
        {{ time }}
      </div>
      <div class="my-auto pl-3">
        <div class="mb-2">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="time"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab x-small color="editColor" class="white--text edit-icon" v-model="time" v-bind="attrs" v-on="on"
                ><v-icon small>mdi-pencil</v-icon></v-btn
              >
            </template>
            <v-time-picker
              v-if="modal"
              v-model="time"
              @change="changeTime"
              full-width
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                キャンセル
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(time)">
                OK
              </v-btn>
            </v-time-picker>
          </v-dialog>
        </div>
        <div class="mt-2">
          <v-btn icon @click="getCurrentTime()"
            ><v-icon>mdi-refresh</v-icon></v-btn
          >
        </div>
      </div>
    </div>
</template>

<script>
import dayjs from "@/plugins/dayjs";
export default {
  props: {
    worktime: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      time: dayjs().format("H:mm"),
      modal: false,
    };
  },
  methods: {
    getCurrentTime() {
      this.time = dayjs().format("H:mm");
    },
    changeTime(a) {
      this.time = a;
      this.$emit("update", a);
    },
  },

  watch: {
    time(newVal) {
      this.changeTime(newVal);
    },
    worktime(wt) {
      if (wt && wt.clock_out) {
        this.changeTime(wt.clock_out);
      }
      this.changeTime(this.time);
    },
  },
};
</script>

<style lang="scss" scoped>
.clock-time {
  font-size: 100px;
}
.edit-icon.theme--light.v-btn--active::before{
  opacity: 0;
}
</style>
