<template>
  <div>
      <div
        class="header pa-4"
        @click="goToPendingWorkList"
        v-if="worktimePendingCount > 0"
      >
        <h1>
          アウトしていない勤怠が {{ worktimePendingCount }} 件あります。
        </h1>
      </div>

      <v-card v-if="task" class="mt-5 bg-blk">
        <v-row class="confirm-sec">
          <v-col cols="12">
            <div class="wt-id">WT-{{ worktime ? worktime.id : "未設定" }}</div>
            <strong> {{ task.customer.name }} {{ task.site.name }} </strong>
            <div class="service-name">{{ task.service.name }}</div>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="3" class="text-right">月日：</v-col>
              <v-col cols="9">{{ formatDate(task.date) }}</v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="3" class="text-right">タイプ：</v-col>
              <v-col cols="9">
                {{
                  worktime
                    ? getClockType(worktime.type_id)
                    : clockType
                    ? clockType.text
                    : "未設定"
                }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <!-- <v-card elevation="2" outlined :color="cardColor">
        <v-card-text>
          <v-card-actions class="pa-0">
            <div class="text-caption">
              WT-{{ worktime ? worktime.id : "未設定" }}
            </div>
            <v-spacer></v-spacer>
            <v-btn depressed fab x-small
              ><v-icon>mdi-arrow-right</v-icon></v-btn
            >
          </v-card-actions>

          <div class="font-weight-bold">
            顧客名・物件名 {{ task.customer.name }} {{ task.site.name }}
          </div>
          <div>Service Name {{ task.service.name }}</div>

          <v-simple-table class="mt-2" dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="text-right">月日 :</td>
                  <td>{{ formatDate(task.date) }}</td>
                </tr>
                <tr>
                  <td class="text-right">タイプ :</td>
                  <td class="text-gray">
                    {{
                      worktime
                        ? getClockType(worktime.type_id)
                        : clockType
                        ? clockType.text
                        : "未設定"
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    clockType: {
      type: Object,
      default: null,
      required: false,
    },
    cardColor: {
      type: String,
      default: "#ffffff",
      required: false,
    },
    worktime: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["task", "worktimePendingCount"]),
  },
  created() {
    this.$store.dispatch("COUNT_PENDING_WORKTIME");
  },
  methods: {
    formatDate(date) {
      var day = this.getDay(date);
      return dayjs(date).format("YYYY年 M月 D日") + "（" + day + "）";
    },
    getDay(date) {
      var days = [
        "日", //Sun
        "月", //Mon
        "火", //Tue
        "水", //Wed
        "木", //Thu
        "金", //Fri
        "土", //Sat
      ];
      var d = new Date(date);
      var dayName = days[d.getDay()];
      return dayName;
    },
    getClockType(type_id) {
      let type = "";
      switch (type_id) {
        case 0:
          type = "現場勤務（リ）";
          break;
        case 1:
          type = "現場勤務（個）";
          break;
        case 2:
          type = "運転・調整";
          break;
        case 3:
          type = "参加";
          break;
        case 4:
          type = "タスク";
          break;
        case 5:
          type = "その他";
          break;

        default:
          break;
      }
      return type;
    },
    goToPendingWorkList() {
      this.$router.push({
        name: "worktime",
        query: { pending: 1 },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: #ff8585;
  cursor: pointer;
}
.header h1 {
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 1;
}
.title {
  font-size: 14px !important;
}
.theme--light.v-input,
.theme--light.v-input input {
  text-align: center;
  font-size: 12px;
}
.bg-blk {
  background: #fffff0 !important;
  border: 1px solid #bdbdbd !important;
  border-radius: 5px;
  box-shadow: 0px 0px !important;
}
.confirm-sec {
  padding: 10px;
  font-size: 14px;
}
.confirm-sec .wt-id {
  font-size: 12px;
  color: #828282;
}
.confirm-sec strong {
  font-size: 15px;
}
</style>
